"use client";

import {
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { createContext, ReactNode, useEffect, useState } from "react";
import { auth } from "../firebase/client";
import axios from "axios";
import Cookies from "js-cookie";
import { useRouter, usePathname } from "next/navigation";
import { removeAuthToken, setToken } from "../actions/auth";

export function getAuthToken() {
  return Cookies.get("firebaseIdToken");
}

export function setAuthToken(token: string) {
  return Cookies.set("firebaseIdToken", token, { secure: true });
}

export function removeToken() {
  removeAuthToken();
  return Cookies.remove("firebaseIdToken");
}

export type AuthContextType = {
  currentUser: any;
  login: (email: string, password: string, cb: any) => any;
  signOutUser: () => void;
  error: string | null;
  loadingSI: boolean;
  role: string;
  permissions: string[];
};

// const AdminList = [
//   "2bKmKkKI99OuDoUBZ1lQeSD2dNv2",
//   "7q9pGXIyenRNaXvC70KAIx8hciK2",
// ];

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const pathname = usePathname();

  const [currentUser, setCurrentUser] = useState<unknown>(null);
  const [error, setError] = useState<any>(null);
  const [loadingSI, setLoadingSI] = useState<boolean>(false);
  const [role, setRole] = useState<any>("free");
  const [permissions, setPermissions] = useState<any>([]);

  const login = (email: string, password: string, cb: any) => {
    setLoadingSI(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(async (res: any) => {
        const tokenResult = await res.user.getIdTokenResult();
        if (tokenResult.claims?.userType === "org") {
          router.push("/dashboard");
        } else if (tokenResult.claims?.userType === "sub-org") {
          router.push("/visitors");
        } else {
          router.push("/investor-dashboard");
        }
        setLoadingSI(false);
        cb();
        setError(null);
      })
      .catch((error) => {
        console.log("error1", error);
        setLoadingSI(false);
        setError("Invalid email or password! Please try again!");
      });
  };

  const signOutUser = () => {
    signOut(auth).then((_res) => {
      setCurrentUser(null);
      router.push("/");
    });
  };

  useEffect(() => {
    if (auth) {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (!user) {
          removeToken();
          router.push("/");
        }
        if (user?.uid) {
          const tokenResult = await user.getIdTokenResult();
          const token = tokenResult?.token;
          const refreshToken = user.refreshToken;

          if (token && refreshToken) {
            setAuthToken(token);
            setToken(token, refreshToken);
            if (tokenResult?.claims) {
              setRole(tokenResult.claims?.role);
              setPermissions(tokenResult.claims?.permissions);
            }

            if (currentUser === null) {
              axios
                .get(`/api/user/${user?.uid}`, {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .then(function (res: any) {
                  if (res?.data?.data) {
                    if (res.data.data?.role === "SUB-ORG") {
                      setCurrentUser({
                        ...res.data.data,
                        id: res.data.data.subOrgId,
                      });
                    } else {
                      setCurrentUser(res.data.data);
                    }
                  } else {
                    if (
                      !(
                        pathname === "/reset-password" ||
                        pathname === "/sign-in" ||
                        pathname === "/forgot-password"
                      )
                    ) {
                      router.push("/");
                      setCurrentUser(null);
                    }
                  }
                })
                .catch(function (error: any) {
                  setCurrentUser(null);
                  console.log("errror", error);
                });
            }
          }
        }
      });
      return () => unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider
      value={{
        login,
        signOutUser,
        currentUser,
        error,
        loadingSI,
        role,
        permissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
